import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
    const [name, setName] = React.useState("Nothing");
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Right now, I'm pretty sure your name is {name}.
        </p>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.currentTarget.value)}
          />
          <button onClick={e => setName("Aaron")}>
              Reset
          </button>
      </header>
    </div>
  );
}

export default App;
